$source-font-path: "../../resources/fonts/now" !default;

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Black.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Bold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Medium.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Light.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Thin.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Black.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Bold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Medium.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Light.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Thin.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
}