@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./utils/imports/import-fonts.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Now", sans-serif, "Font Awesome 6 Free";
}

::-webkit-scrollbar {
  width: 7.5px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-left: 1px solid #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 5px;

  &:hover {
    background: #9b9b9b;
  }
}

li::marker {
  color: theme('colors.main');
}

::-webkit-search-clear-button {
  color: red;
  cursor: pointer;
}